import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Array, Literal, Record, Static, String } from 'runtypes';

import AspectBox from '../../components/AspectBox';
import Img from '../../components/Img';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { prismicApiGraphqlEndpoint } from '../../config/prismic-api';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import { usePrismicMasterRef } from '../../util/use-prismic-master-ref';

import './InstaLinks.scss';

//
const instaLinksQuery = `
  query {
    allInsta_linkss {
      edges {
        node {
          links {
            link {
              __typename
              ... on Experience {
                title
                slug
                main_photo
              }
              ... on Post {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const TitleFieldSchema = Array(
  Record({
    type: Literal('heading1'),
    text: String,
  }),
);

const APIInstaLinksResponseSchema = Record({
  data: Record({
    allInsta_linkss: Record({
      edges: Array(
        Record({
          node: Record({
            links: Array(
              Record({
                link: Record({
                  __typename: Literal('Experience'),
                  title: TitleFieldSchema,
                  slug: TitleFieldSchema,
                  main_photo: Record({
                    url: String,
                  }),
                }).Or(
                  Record({
                    __typename: Literal('Post'),
                    title: TitleFieldSchema,
                    slug: TitleFieldSchema,
                  }),
                ),
              }),
            ),
          }),
        }),
      ),
    }),
  }),
});

type APIInstaLinksResponse = Static<typeof APIInstaLinksResponseSchema>['data']['allInsta_linkss']['edges'][number]['node']['links'];

//
const InstaLinksLoading: React.FC = () => (
  <div className="insta-links-loading">
    <Spinner label="Carregando..." />
  </div>
);

const InstaLinksError: React.FC = () => (
  <div className="insta-links-error">
    <p>
      <span role="img" aria-label="Emoji">😵</span> Ops! Fizemos alguma besteira e algo não deu certo.
      {' '}Você pode tentar atualizar a página?
    </p>
  </div>
);

const InstaLinks: React.FC = () => {
  const [instaLinks, setInstaLinks] = useState<undefined | false | APIInstaLinksResponse>();

  const masterRef = usePrismicMasterRef();

  useEffect(() => {
    if (!masterRef) return;

    (async (): Promise<void> => {
      try {
        const response = await fetch(
          prismicApiGraphqlEndpoint + '?query=' + encodeURIComponent(instaLinksQuery),
          {
            headers: {
              'Prismic-Ref': masterRef,
            },
          },
        );

        const json = await response.json();

        if (APIInstaLinksResponseSchema.guard(json)) {
          if (json.data.allInsta_linkss.edges.length === 1 && json.data.allInsta_linkss.edges[0].node.links.length > 0) {
            const links = [...json.data.allInsta_linkss.edges[0].node.links].reverse().slice(0, 20);
            setInstaLinks(links);
          } else {
            setInstaLinks(false);
          }
        } else {
          setInstaLinks(false);
        }
      } catch {
        setInstaLinks(false);
      }
    })();
  }, [masterRef]);

  return (
    <Layout mainClassName="insta-links">
      {/* {instaLinks === undefined && <InstaLinksLoading />} */}

      <div className="container">
        <h2>Últimas do Instagram</h2>

        {instaLinks === undefined && <InstaLinksLoading />}
        {instaLinks === false && <InstaLinksError />}

        {instaLinks && (
          <div className="insta-links-results">
            {instaLinks.map(({ link }) => (
              <React.Fragment key={link.slug[0].text}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {link.__typename === 'Experience' && (
                  <div className="insta-links-experience">
                    <Link to={`/${link.slug[0].text}`}>
                      <AspectBox heightRatio={1 / 0.75}>
                        <Img src={buildImgixUrlWithAspectRatio(link.main_photo.url, 1 / 0.75, 400)} />
                      </AspectBox>

                      <h2>{link.title[0].text}</h2>
                    </Link>
                  </div>
                )}

                {/* eslint-disable-next-line no-underscore-dangle */}
                {link.__typename === 'Post' && (
                  <div className="insta-links-post">
                    <Link to={`/${link.slug[0].text}`}>
                      <h2>{link.title[0].text}</h2>
                    </Link>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default InstaLinks;
